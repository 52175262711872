import * as Sentry from "@sentry/vue";
import { createApp } from 'vue';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

import '@/assets/styles.scss';
import '@/assets/tailwind.css';

const app = createApp(App);

import Aura from '@primevue/themes/aura';
import { definePreset } from '@primevue/themes';

app.use(PrimeVue, {
    theme: {
        preset: definePreset(Aura, {
            semantic: {
                primary: {
                    50: '{surface.50}',
                    100: '{surface.100}',
                    200: '{surface.200}',
                    300: '{surface.300}',
                    400: '{surface.400}',
                    500: '{surface.500}',
                    600: '{surface.600}',
                    700: '{surface.700}',
                    800: '{surface.800}',
                    900: '{surface.900}',
                    950: '{surface.950}'
                },
                colorScheme: {
                    light: {
                        primary: {
                            color: '{primary.950}',
                            contrastColor: '#ffffff',
                            hoverColor: '{primary.800}',
                            activeColor: '{primary.700}'
                        },
                        highlight: {
                            background: '{primary.950}',
                            focusBackground: '{primary.700}',
                            color: '#ffffff',
                            focusColor: '#ffffff'
                        }
                    },
                    dark: {
                        primary: {
                            color: '{primary.50}',
                            contrastColor: '{primary.950}',
                            hoverColor: '{primary.200}',
                            activeColor: '{primary.300}'
                        },
                        highlight: {
                            background: '{primary.50}',
                            focusBackground: '{primary.300}',
                            color: '{primary.950}',
                            focusColor: '{primary.950}'
                        }
                    }
                }
            }
        }),
        options: {
            primary: 'noir',
            surface: 'slate',
            darkModeSelector: '.app-dark'
        }
    }
});
app.use(ToastService);
app.use(ConfirmationService);

import { registerPlugins } from '@/plugins'
registerPlugins(app)

import setupI18n, { loadLocaleMessages } from '@/plugins/i18n';
const i18n = setupI18n();
app.use(i18n);

import { env } from '@/plugins/env';
import { axios } from '@/plugins/axios';
import Echo from 'laravel-echo';
import * as Pusher from 'pusher-js/dist/web/pusher';

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: env.get('PUSHER_APP_KEY'),
    wsHost: env.get('WEBSOCKET_HOST'),
    wsPort: env.get('WEBSOCKET_PORT'),
    disableStats: true,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post(env.get('VITE_WEBSOCKET_AUTH_ENDPOINT'),{
                        socket_id: socketId,
                        channel_name: channel.name,
                    })
                    .then((response) => {
                        callback(null, response.data)
                    })
                    .catch((error) => {
                        callback(error)
                    })
            },
        }
    }
});

import { useRouter } from 'vue-router';
const router = useRouter();
Sentry.init({
    app,
    dsn: env.get("SENTRY_DSN"),
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.1, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// import i18nInstance from '@/plugins/i18n';
app.mount('#app');
