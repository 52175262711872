import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { env } from '@/plugins/env';

export default function setupI18n() {
    const locale = env.get('I18N_LOCALE', 'en')
    const fallbackLocale = env.get('I18N_FALLBACK_LOCALE', 'en')
    const i18n = createI18n({
        locale,
        fallbackLocale,
        silentTranslationWarn: true,
        globalInjection: true,
        formatFallbackMessages: true
    })
    setI18nLanguage(i18n, locale)
    return i18n
}

export function setI18nLanguage(i18n, locale) {
    if (i18n.mode === 'legacy') {
        i18n.global.locale = locale
    } else {
        i18n.global.locale.value = locale
    }
    document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages({setLocaleMessage}, locale) {
    const messages = await import(`@/lang/${locale}.json`)
    setLocaleMessage(locale, messages.default)
    return nextTick()
}
