<script setup>
definePage({
    meta: {
        layout: "registration",
    }
})
</script>

<template>
    <div>
        <router-view />
    </div>
</template>
