class Env {
  get(key, fallback = null) {
      let env = window.configs || import.meta.env
      return env[key] || env[`VITE_${key}`] || fallback
  }
}
export const env = new Env()

export default {
  install: (app) => {
    app.config.globalProperties.$env = env
    app.provide('env', env)
  }
}
