<script setup>
definePage({
    meta: {
        layout: "auth"
    }
})
</script>

<template>
    <div>
        <router-view />
    </div>
</template>
