import dayjs from 'dayjs/esm'
import 'dayjs/esm/locale/de'

import LocalizedFormat from 'dayjs/esm/plugin/localizedFormat'
dayjs.extend(LocalizedFormat)

import relativeTime from 'dayjs/esm/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
    install: (app) => {
        app.config.globalProperties.$dayjs = dayjs
        app.provide('dayjs', dayjs)
    }
}

export { dayjs }
