import _definePage_default_0 from '/app/src/pages/auth.vue?definePage&vue&lang.tsx'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/app/src/pages/registration.vue?definePage&vue&lang.tsx'

export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('/app/src/pages/index.vue'),
    /* no children */
  },
  _mergeRouteRecord(
  {
    path: '/auth',
    name: '/auth',
    component: () => import('/app/src/pages/auth.vue'),
    children: [
      {
        path: 'forgot-password',
        name: '/auth/forgot-password',
        component: () => import('/app/src/pages/auth/forgot-password.vue'),
        /* no children */
      },
      {
        path: 'login',
        name: '/auth/login',
        component: () => import('/app/src/pages/auth/login.vue'),
        /* no children */
      },
      {
        path: 'reset-password/:token',
        name: '/auth/reset-password.[token]',
        component: () => import('/app/src/pages/auth/reset-password.[token].vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_0
  ),
  {
    path: '/billing',
    /* internal name: '/billing' */
    /* no component */
    children: [
      {
        path: '',
        name: '/billing/',
        component: () => import('/app/src/pages/billing/index.vue'),
        children: [
          {
            path: 'invoices',
            name: '/billing//invoices',
            component: () => import('/app/src/pages/billing/index/invoices.vue'),
            /* no children */
          },
          {
            path: 'payment-methods',
            name: '/billing//payment-methods',
            component: () => import('/app/src/pages/billing/index/payment-methods.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/databases',
    /* internal name: '/databases' */
    /* no component */
    children: [
      {
        path: '',
        name: '/databases/',
        component: () => import('/app/src/pages/databases/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/databases/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/databases/[id]/edit',
            component: () => import('/app/src/pages/databases/[id]/edit.vue'),
            children: [
              {
                path: 'general',
                name: '/databases/[id]/edit/general',
                component: () => import('/app/src/pages/databases/[id]/edit/general.vue'),
                /* no children */
              },
              {
                path: 'performance',
                name: '/databases/[id]/edit/performance',
                component: () => import('/app/src/pages/databases/[id]/edit/performance.vue'),
                /* no children */
              },
              {
                path: 'storage',
                name: '/databases/[id]/edit/storage',
                component: () => import('/app/src/pages/databases/[id]/edit/storage.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'create',
        name: '/databases/create',
        component: () => import('/app/src/pages/databases/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/domains',
    /* internal name: '/domains' */
    /* no component */
    children: [
      {
        path: '',
        name: '/domains/',
        component: () => import('/app/src/pages/domains/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/domains/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/domains/[id]/edit',
            component: () => import('/app/src/pages/domains/[id]/edit.vue'),
            children: [
              {
                path: 'connectivity',
                name: '/domains/[id]/edit/connectivity',
                component: () => import('/app/src/pages/domains/[id]/edit/connectivity.vue'),
                /* no children */
              },
              {
                path: 'contacts',
                name: '/domains/[id]/edit/contacts',
                component: () => import('/app/src/pages/domains/[id]/edit/contacts.vue'),
                /* no children */
              },
              {
                path: 'dns',
                name: '/domains/[id]/edit/dns',
                component: () => import('/app/src/pages/domains/[id]/edit/dns.vue'),
                /* no children */
              },
              {
                path: 'general',
                name: '/domains/[id]/edit/general',
                component: () => import('/app/src/pages/domains/[id]/edit/general.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'create',
        name: '/domains/create',
        component: () => import('/app/src/pages/domains/create.vue'),
        /* no children */
      },
      {
        path: 'create/external',
        name: '/domains/create.external',
        component: () => import('/app/src/pages/domains/create.external.vue'),
        /* no children */
      },
      {
        path: 'create/registration',
        name: '/domains/create.registration',
        component: () => import('/app/src/pages/domains/create.registration.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/projects',
    /* internal name: '/projects' */
    /* no component */
    children: [
      {
        path: '',
        name: '/projects/',
        component: () => import('/app/src/pages/projects/index.vue'),
        /* no children */
      },
      {
        path: ':id/edit',
        name: '/projects/[id].edit',
        component: () => import('/app/src/pages/projects/[id].edit.vue'),
        /* no children */
      },
      {
        path: 'create',
        name: '/projects/create',
        component: () => import('/app/src/pages/projects/create.vue'),
        /* no children */
      }
    ],
  },
  _mergeRouteRecord(
  {
    path: '/registration',
    name: '/registration',
    component: () => import('/app/src/pages/registration.vue'),
    children: [
      {
        path: '',
        name: '/registration/',
        component: () => import('/app/src/pages/registration/index.vue'),
        /* no children */
      },
      {
        path: 'password',
        name: '/registration/password',
        component: () => import('/app/src/pages/registration/password.vue'),
        /* no children */
      },
      {
        path: 'payment-method',
        name: '/registration/payment-method',
        component: () => import('/app/src/pages/registration/payment-method.vue'),
        /* no children */
      },
      {
        path: 'verify',
        name: '/registration/verify',
        component: () => import('/app/src/pages/registration/verify.vue'),
        /* no children */
      }
    ],
  },
  _definePage_default_2
  ),
  {
    path: '/routes',
    /* internal name: '/routes' */
    /* no component */
    children: [
      {
        path: '',
        name: '/routes/',
        component: () => import('/app/src/pages/routes/index.vue'),
        /* no children */
      },
      {
        path: ':id/edit',
        name: '/routes/[id].edit',
        component: () => import('/app/src/pages/routes/[id].edit.vue'),
        /* no children */
      },
      {
        path: 'create',
        name: '/routes/create',
        component: () => import('/app/src/pages/routes/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/settings',
    /* internal name: '/settings' */
    /* no component */
    children: [
      {
        path: '',
        name: '/settings/',
        component: () => import('/app/src/pages/settings/index.vue'),
        children: [
          {
            path: 'account',
            name: '/settings//account',
            component: () => import('/app/src/pages/settings/index/account.vue'),
            /* no children */
          },
          {
            path: 'auth',
            name: '/settings//auth',
            component: () => import('/app/src/pages/settings/index/auth.vue'),
            /* no children */
          },
          {
            path: 'dpa',
            name: '/settings//dpa',
            component: () => import('/app/src/pages/settings/index/dpa.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/spaces',
    /* internal name: '/spaces' */
    /* no component */
    children: [
      {
        path: '',
        name: '/spaces/',
        component: () => import('/app/src/pages/spaces/index.vue'),
        /* no children */
      },
      {
        path: ':id',
        /* internal name: '/spaces/[id]' */
        /* no component */
        children: [
          {
            path: 'edit',
            name: '/spaces/[id]/edit',
            component: () => import('/app/src/pages/spaces/[id]/edit.vue'),
            children: [
              {
                path: 'cronjobs',
                name: '/spaces/[id]/edit/cronjobs',
                component: () => import('/app/src/pages/spaces/[id]/edit/cronjobs.vue'),
                children: [
                  {
                    path: ':cronjob_id/edit',
                    name: '/spaces/[id]/edit/cronjobs/[cronjob_id].edit',
                    component: () => import('/app/src/pages/spaces/[id]/edit/cronjobs/[cronjob_id].edit.vue'),
                    /* no children */
                  },
                  {
                    path: 'create',
                    name: '/spaces/[id]/edit/cronjobs/create',
                    component: () => import('/app/src/pages/spaces/[id]/edit/cronjobs/create.vue'),
                    /* no children */
                  },
                  {
                    path: 'list',
                    name: '/spaces/[id]/edit/cronjobs/list',
                    component: () => import('/app/src/pages/spaces/[id]/edit/cronjobs/list.vue'),
                    /* no children */
                  }
                ],
              },
              {
                path: 'general',
                name: '/spaces/[id]/edit/general',
                component: () => import('/app/src/pages/spaces/[id]/edit/general.vue'),
                /* no children */
              },
              {
                path: 'ssh',
                name: '/spaces/[id]/edit/ssh',
                component: () => import('/app/src/pages/spaces/[id]/edit/ssh.vue'),
                /* no children */
              },
              {
                path: 'webserver',
                name: '/spaces/[id]/edit/webserver',
                component: () => import('/app/src/pages/spaces/[id]/edit/webserver.vue'),
                /* no children */
              }
            ],
          }
        ],
      },
      {
        path: 'create',
        name: '/spaces/create',
        component: () => import('/app/src/pages/spaces/create.vue'),
        /* no children */
      }
    ],
  },
  {
    path: '/support',
    /* internal name: '/support' */
    /* no component */
    children: [
      {
        path: '',
        name: '/support/',
        component: () => import('/app/src/pages/support/index.vue'),
        /* no children */
      }
    ],
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

