/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import axios from '@/plugins/axios';
import env from '@/plugins/env';
import auth from '@/plugins/auth';
import router from '@/router';
import pinia from '@/stores';
import dayjs from '@/plugins/dayjs';

export function registerPlugins(app) {
    app.use(pinia)
        .use(axios)
        .use(env)
        .use(auth)
        .use(router)
        .use(dayjs)
}
