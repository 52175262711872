import {axios} from '@/plugins/axios'
import router from '@/router'
import {env} from "@/plugins/env";

class Auth {

  async fetchUser(redirect = true) {
    try {
      const response = await axios.get('admin-users/me')
      return response.data.data
    } catch (error) {
      if (error.response.status === 401 && redirect) {
        router.push({ name: '/login' })
      }

      return null;
    }
  }

  sanctumCSRF() {
    return axios.get(env.get('SANCTUM_URL'))
  }

  async login(credentials) {
    await this.sanctumCSRF()
    return await axios.post('login', credentials)
  }

  async logout() {
    return await axios.post('logout')
  }
}

const AuthenticateInstance = new Auth()
export default {
  install: (app) => {
    app.config.globalProperties.$auth = AuthenticateInstance
    app.provide('auth', AuthenticateInstance)
  }
}

export const auth = AuthenticateInstance
