// src/plugins/axios.js
import axios from 'axios';
import { env } from '@/plugins/env';

const axiosInstance = axios.create({
    baseURL: env.get('API_BASE_URL'),
    timeout: env.get('API_TIMEOUT', 3000),
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    },
    withCredentials: true,
    withXSRFToken: true
});

export default {
    install: (app) => {
        app.config.globalProperties.$axios = axiosInstance;
        app.provide('axios', axiosInstance);

        axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    if (window.location.pathname !== '/auth/login') {
                        window.location = '/auth/login'
                    }
                }

                return Promise.reject(error);
            }
        );
    }
};

export { axiosInstance as axios };
